<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">突出普遍价值（SOUV）声明材料信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="数据密级" prop="sjmj">
                    <el-select
                        v-model="formdata.saveJson.sjmj"
                        :disabled="!isEdit"
                        placeholder="请选择数据密级"
                        @change="changeSJMJ"
                    >
                        <el-option label="公开" value="公开"></el-option>
                        <el-option label="不公开" value="不公开"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-show="formdata.saveJson.sjmj === '公开'"
                    label="发布范围"
                    prop="fbfw"
                >
                    <el-select
                        v-model="formdata.saveJson.fbfw"
                        :disabled="!isEdit"
                        placeholder="请选择发布范围"
                    >
                        <el-option
                            label="互联网公开"
                            value="互联网公开"
                        ></el-option>
                        <el-option
                            label="行业内部公开"
                            value="行业内部公开"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="声明材料类型" prop="lx">
                    <el-select
                        v-model="formdata.saveJson.lx"
                        filterable
                        clearable
                        :disabled="!isEdit"
                        placeholder="请选择声明材料类型"
                    >
                        <el-option
                            label="回顾性突出普遍价值声明"
                            value="1"
                        ></el-option>
                        <el-option
                            label="突出普遍价值声明"
                            value="0"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="采纳时间" prop="cnsmcldsj">
                    <el-date-picker
                        v-model="formdata.saveJson.cnsmcldsj"
                        :disabled="!isEdit"
                        value-format="yyyy"
                        type="year"
                        placeholder="请选择采纳时间"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="列入标准" prop="lrbz">
                    <el-select
                        v-model="lrbz"
                        :disabled="!isEdit"
                        multiple
                        filterable
                        clearable
                        placeholder="请选择列入标准"
                        @change="changeLrbz"
                    >
                        <el-option
                            v-for="(item, index) in lrbzList"
                            :key="index"
                            :value="item"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="资料名称" prop="zlmc">
                    <el-input
                        v-model="formdata.saveJson.zlmc"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <div
                    v-for="(item, index) in formdata.saveJson.lrbzdata"
                    :key="index"
                >
                    <el-form-item
                        class="from-item-block"
                        :label="item.lrbz + '英文'"
                        :prop="'lrbzdata.' + [index] + '.yw'"
                        :rules="{
                            required: true,
                            message: '请输入列入标准' + item.lrbz + '英文',
                            trigger: 'blur',
                        }"
                    >
                        <el-input
                            v-model="item.yw"
                            :disabled="!isEdit"
                            :autosize="{ minRows: 6 }"
                            show-word-limit
                            type="textarea"
                            :placeholder="'请输入列入标准' + item.lrbz + '英文'"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        class="from-item-block"
                        :label="item.lrbz + '对应中文'"
                        :prop="'lrbzdata.' + [index] + '.dyzw'"
                        :rules="{
                            required: true,
                            message: '请输入列入标准' + item.lrbz + '对应中文',
                            trigger: 'blur',
                        }"
                    >
                        <el-input
                            v-model="item.dyzw"
                            :disabled="!isEdit"
                            :autosize="{ minRows: 6 }"
                            show-word-limit
                            type="textarea"
                            :placeholder="
                                '请输入列入标准' + item.lrbz + '对应中文'
                            "
                        ></el-input>
                    </el-form-item>
                </div>
                <el-form-item
                    class="from-item-block"
                    label="简要综述英文"
                    prop="zsdyyw"
                >
                    <el-input
                        v-model="formdata.saveJson.zsdyyw"
                        :disabled="!isEdit"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        show-word-limit
                        placeholder="请输入简要综述英文"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    class="from-item-block"
                    label="简要综述对应中文"
                    prop="zszw"
                >
                    <el-input
                        v-model="formdata.saveJson.zszw"
                        :disabled="!isEdit"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        show-word-limit
                        placeholder="请输入简要综述对应中文"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    class="from-item-block"
                    label="完整性英文"
                    prop="wzxdyyw"
                >
                    <el-input
                        v-model="formdata.saveJson.wzxdyyw"
                        :disabled="!isEdit"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        show-word-limit
                        placeholder="请输入完整性英文"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    class="from-item-block"
                    label="完整性对应中文"
                    prop="wzxzw"
                >
                    <el-input
                        v-model="formdata.saveJson.wzxzw"
                        :disabled="!isEdit"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        show-word-limit
                        placeholder="请输入完整性对应中文"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    class="from-item-block"
                    label="真实性英文"
                    prop="zsxdyyw"
                >
                    <el-input
                        v-model="formdata.saveJson.zsxdyyw"
                        :disabled="!isEdit"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        show-word-limit
                        placeholder="请输入真实性英文"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    class="from-item-block"
                    label="真实性对应中文"
                    prop="zsxzw"
                >
                    <el-input
                        v-model="formdata.saveJson.zsxzw"
                        :disabled="!isEdit"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        show-word-limit
                        placeholder="请输入真实性对应中文"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    class="from-item-block"
                    label="保护管理要求英文"
                    prop="bhglyqdyyw"
                >
                    <el-input
                        v-model="formdata.saveJson.bhglyqdyyw"
                        :disabled="!isEdit"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        show-word-limit
                        placeholder="请输入保护管理要求英文"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    class="from-item-block"
                    label="保护管理要求对应中文"
                    prop="bhglyqzw"
                >
                    <el-input
                        v-model="formdata.saveJson.bhglyqzw"
                        :disabled="!isEdit"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        show-word-limit
                        placeholder="请输入保护管理要求对应中文"
                    ></el-input>
                </el-form-item>
                <basic-upload
                    v-if="formdata.saveJson.sjmj === '公开'"
                    ref="uploadFile"
                    :isEdit="isEdit"
                    :itemId="itemId"
                    :metaData.sync="formdata.metaJson"
                    basicType="document"
                ></basic-upload>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "souv",
    components: {},
    mixins: [infoMixin],
    data() {
        return {
            snButton: snButton,
            lrbzsort: {
                "(i)": 1,
                "(ii)": 2,
                "(iii)": 3,
                "(iv)": 4,
                "(v)": 5,
                "(vi)": 6,
                "(vii)": 7,
                "(viii)": 8,
                "(ix)": 9,
                "(x)": 10,
            },
            lrbzList: [
                "(i)",
                "(ii)",
                "(iii)",
                "(iv)",
                "(v)",
                "(vi)",
                "(vii)",
                "(viii)",
                "(ix)",
                "(x)",
            ],
            rules: {
                sjmj: [
                    {
                        required: true,
                        message: "请选择数据密级",
                        trigger: "blur",
                    },
                ],
                fbfw: [
                    {
                        required: true,
                        message: "请选择发布范围",
                        trigger: "blur",
                    },
                ],
                lx: [
                    {
                        required: true,
                        message: "请选择声明材料类型",
                        trigger: "change",
                    },
                ],
                cnsmcldsj: [
                    {
                        required: true,
                        message: "请选择采纳时间",
                        trigger: "blur",
                    },
                ],
                lrbz: [
                    {
                        required: true,
                        message: "请选择列入标准",
                        trigger: "change",
                    },
                ],
                zsdyyw: [
                    {
                        required: true,
                        message: "请输入简要综述英文",
                        trigger: "blur",
                    },
                ],
                zszw: [
                    {
                        required: true,
                        message: "请输入简要综述对应中文",
                        trigger: "blur",
                    },
                ],
                wzxdyyw: [
                    {
                        required: true,
                        message: "请输入完整性英文",
                        trigger: "blur",
                    },
                ],
                wzxzw: [
                    {
                        required: true,
                        message: "请输入完整性对应中文",
                        trigger: "blur",
                    },
                ],
                zsxdyyw: [
                    {
                        required: true,
                        message: "请输入真实性英文",
                        trigger: "blur",
                    },
                ],
                zsxzw: [
                    {
                        required: true,
                        message: "请输入真实性对应中文",
                        trigger: "blur",
                    },
                ],
                bhglyqdyyw: [
                    {
                        required: true,
                        message: "请输入保护管理要求英文",
                        trigger: "blur",
                    },
                ],
                bhglyqzw: [
                    {
                        required: true,
                        message: "请输入保护管理要求对应中文",
                        trigger: "blur",
                    },
                ],
                zlmc: [
                    {
                        required: true,
                        message: "请填写资料名称",
                        trigger: "blur",
                    },
                ],
            },
            lrbz: [],
            formdata: {
                saveJson: {
                    sjmj: "公开",
                    fbfw: "行业内部公开",
                    lx: "",
                    cnsmcldsj: "",
                    lrbz: "",
                    lrbzdata: [],
                    lrbzyw: "",
                    lrbzzw: "",
                    zsdyyw: "",
                    zszw: "",
                    wzxdyyw: "",
                    wzxzw: "",
                    zsxdyyw: "",
                    zsxzw: "",
                    bhglyqdyyw: "",
                    bhglyqzw: "",
                    zlmc: "",
                    shzt: 1,
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "3",
                userName: "",
                userId: "",
            },
            lastvalue: [],
        };
    },
    computed: {},
    watch: {
        lrbz() {
            this.formdata.saveJson.lrbz = this.lrbz.join("#");
        },
    },
    mounted() {},
    methods: {
        operateData(type) {
            let saveJson = this.formdata.saveJson;
            if (type === "save") {
                saveJson.lrbzzw = saveJson.lrbzdata
                    .map((item) => item.dyzw)
                    .join("#");
                saveJson.lrbzyw = saveJson.lrbzdata
                    .map((item) => item.yw)
                    .join("#");
                saveJson.lrbz = this.lrbz.join("#");
            } else {
                this.lrbz = saveJson.lrbz.split("#");
                let zwdata = saveJson.lrbzzw.split("#");
                let ywdata = saveJson.lrbzyw.split("#");
                this.lrbz.forEach(function(item, index) {
                    let obj = {
                        lrbz: item,
                        yw: ywdata[index],
                        dyzw: zwdata[index],
                    };
                    saveJson.lrbzdata.push(obj);
                });
                this.lastvalue = this.lrbz;
            }
        },
        changeLrbz(value) {
            this.lrbz.sort((a, b) => {
                return this.lrbzsort[a] - this.lrbzsort[b];
            });
            if (
                this.lastvalue.length < value.length ||
                this.lastvalue.length === 0
            ) {
                // 增加
                let data = value.filter((item) => {
                    return !this.lastvalue.includes(item);
                });
                this.formdata.saveJson.lrbzdata.push({
                    lrbz: data[0],
                    yw: "",
                    dyzw: "",
                });
            } else {
                // 减少
                let data = this.lastvalue.filter((item) => {
                    return !value.includes(item);
                });
                this.formdata.saveJson.lrbzdata = this.formdata.saveJson.lrbzdata.filter(
                    (item) => {
                        return item.lrbz !== data[0];
                    }
                );
            }
            this.formdata.saveJson.lrbzdata.sort((a, b) => {
                return this.lrbzsort[a.lrbz] - this.lrbzsort[b.lrbz];
            });
            this.lastvalue = value;
            this.$refs.formdata.clearValidate();
        },
    },
};
</script>
<style lang="scss" scoped></style>
